<template>
  <div
    v-if="data.fields"
  >
    <div class="banner-wrapper" :class="`remove-padding-${data.fields.removePadding} reverse-order-${data.fields.reverseOrder}`">
      <img class="banner-image" :src="data.fields.image.fields.file.url" v-if="data.fields.image" />

      <div>
        <div class="description" v-html="mdToHTML(data.fields.description)" v-if="data.fields.description"></div>
        <div class="image-set-wrapper" v-if="data.fields.imageSet">
          <div class="image-set-item" v-for="image in data.fields.imageSet" :style="{backgroundImage: `url(${image.fields.file.url})`}">
          </div>
        </div>

        <el-row v-if="data.fields.cta && data.fields.ctaTarget">
          <el-button
            class="delay-entry cta-button"
            type="danger"
            round
            @click="goToLink(data.fields.ctaTarget)"
          >{{data.fields.cta}}</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'Banner',

  transition: 'page',

  props: [ 'data' ],

  methods: {
    goToLink (target) {
      if (target.indexOf('https://') === 0) {
        window.location.href = target
        return
      }
      this.$router.push(target)
    },
    mdToHTML (markdown) {
      return documentToHtmlString(markdown)
    }
  },
}
</script>

<style scoped lang="scss">
.banner-wrapper {
  &.reverse-order-true {
    flex-direction: column-reverse;
  }

  display: flex;
  flex-direction: column;
  @include breakpoint($tablet) {
    flex-direction: row !important;
    justify-content: space-around;
    align-items: center;    
    max-width: 900px;
    margin: 2em auto;
  }
  &.remove-padding-Bottom {
    @include breakpoint($tablet) {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.banner-image {
  width: 100%;
  @include breakpoint($tablet) {
    max-width: 500px;
    height: auto;
    width: 100%;
  }
}

.cta-button {
  margin-bottom: 2em;
}
.image-set-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .image-set-item {
    width: 150px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 1em;
    @include breakpoint($tablet) {
      width: 200px;
      height: 120px;
      margin: 0.5em 1em;
    }
  }
}

</style>
<style lang="scss">
.banner-wrapper {
  .description {
    p {
      padding: 2em 2em 1em;
      font-size: 1.4em;
      @include breakpoint($tablet) {
        padding: 0.5em;
        font-size: 28px;
      }
    }
  }
}
</style>